<template>
  <div class="home">
    <ClassroomCreate />
  </div>
</template>

<script>
// @ is an alias to /src
import ClassroomCreate from '@/components/classroom/classroomCreate/ClassroomCreator'

export default {
  name: 'ClassroomMentorView',
  components: {
    ClassroomCreate
  }
}
</script>
